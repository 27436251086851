import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {getOrganizationTeam} from "../store/user_organizations/teams/team";
import {setRouteSelectedOrgId, setRouteSelectedTeamId} from "../store/routes";
import {useNavigate} from "react-router-dom";

export const FacilitatorDashboardRedirectHandler = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const organization_id = searchParams.get('organization_id');
    const team_id = searchParams.get('team_id');
    const endpoint = searchParams.get('endpoint');

    useEffect(() => {
        dispatch(getOrganizationTeam({organization_id , team_id}) );
        dispatch(setRouteSelectedOrgId(organization_id))
        dispatch(setRouteSelectedTeamId(team_id))

        navigate('/facilitator-dashboard/' + endpoint)
    }, [])

    return (
        <>
            Loading...
        </>
    )
}
