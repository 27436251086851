import React, {useEffect} from 'react'
import { zynity_website } from '../config/external-routes'
// @ts-ignore
import { persistor } from "../store/store.js";

export const LogOut = () => {
    useEffect(() => {
        persistor.pause();
        persistor.flush().then(() => {
            return persistor.purge().then(() => {
                console.log("destroyed");
                window.location.href = zynity_website.yii_website_logout_url
            });
        });
    }, [])
    return (
        <>
        </>
    )
}
